<template>
  <div class="row mt-2">
    <h4 class=" border-top pt-4">{{ $t("productos.caracteristicasNutricionales") }}</h4>
    <div class="row border-bottom py-3 px-0">
      <div class="col" v-for="column in columns" :key="columns.indexOf(column)">
        <ul>
          <li
            class="item-container"
            v-for="item in column"
            :key="column.indexOf(item)"
            :title="item.detail"
          >
            <span v-html="item.name"></span>
            <i v-if="item.detail" style="cursor: pointer">*</i>
            <img v-if="item.sinTacc" src="@/assets/images/icons/sintacc.png" class="tacc"/>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CaracteristicasNutricionales",
  props: {
    columnas: Number,
    caracteristicas: Object,
  },
  data() {
    return {
      cols: this.columnas,
      // items: this.caracteristicas
    };
  },
  computed: {
    items() {
      return this.getCaracteristicas(this.caracteristicas);
    },
    columns() {
      let columns = [];
      let mid = Math.ceil(this.items.length / this.cols);
      for (let col = 0; col < this.cols; col++) {
        columns.push(this.items.slice(col * mid, col * mid + mid));
      }
      return columns;
    },
  },
  methods: {
    getCaracteristicas(list) {
      if (this.$i18n.locale === "en") {
        return list["en"];
      } else {
        return list["es"];
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/constants.scss";
ul {
  padding-left: 16px ;
  list-style-image: url("../assets/images/tilde_celeste-15.png");
}
li {
  margin: 0;
  padding: 2px 0 2px 0;
  // color: #9bb5d7;
  // list-style-type: "\2713"; 
  // list-style-type: "\1F5F8"; 
  // background-image: url("../assets/images/tilde_celeste.png");
  // background-repeat: no-repeat;
  // background-position: left center;
  // background-size: 15px;
  list-style-position: inside;
  font-size: 0.9rem;
}

.tacc {
  max-width: 30px;
}
</style>
