<template>
  <span class="saborSpan" :style="css"> {{ texto }} </span>
</template>

<script>
export default {
  name: "Sabor",
  props: {
    texto: String,
    color: String,
  },
  computed: {
    css() {
      return {'--color': this.color}
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/constants.scss";

span {
  float: left;
  background-color: var(--color);
  /* border-radius: 0 25px 25px 0; */
  padding: 8px 15px 8px 8px;
  margin-top: -5px;
  font-weight:300;
  font-size: 1rem;
  text-align: center;
  color: $gris;
  position: relative;
  max-width: 180px;
}

span::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 100%;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0) var(--color);
  border-width: 24px 0 15px 15px;
  width: 0;
  height: 0;
}


@media (max-width: 425px) {
    .img-full {
        display: none !important;
    }

    .img-responsive {
        display: block !important;
    }
    
  .pl-mobile {
    padding-left: 20px !important;
  }
}


</style>
