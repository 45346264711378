<template>
  <table class="tabla-nutricional table table-sm table-striped table-hover table-responsive mt-5">
    <caption>{{ $t('tablasNutricionales.valoresDiarios') }}</caption>
    <thead class="text-uppercase text-center">
      <tr>
        <th scope="col"></th>
        <th scope="col">{{ $t('tablasNutricionales.cada100G') }}</th>
        <th scope="col">% VD (*)</th>
      </tr>
    </thead>
    <tbody class="text-center">
      <tr>
        <td>{{ $t('tablasNutricionales.valor_energetico') }}</td>
        <td>739 Kcal = 3092 Kj</td>
        <td>37</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.carbohidratos') }}</td>
        <td>22.7 g</td>
        <td>7.4</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_totales') }}</td>
        <td>70 g</td>
        <td>129</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_saturadas') }}</td>
        <td>70 g</td>
        <td>328</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_trans') }}</td>
        <td>0 g</td>
        <td>-</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.proteinas') }}</td>
        <td>4.3 g</td>
        <td>7.5</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.fibra_alimentaria') }}</td>
        <td>0 g</td>
        <td>0</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.sodio') }}</td>
        <td>100 g</td>
        <td>4.2</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.potasio') }}</td>
        <td>700 g</td>
        <td>-</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.calcio') }}</td>
        <td>100 g</td>
        <td>10</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.fosforo') }}</td>
        <td>400 g</td>
        <td>55.6</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "TableMediumChainLipids",
};
</script>
